@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins';

ul.restaurants {
  li {
    cursor: pointer;
  }
}

.detailed-view--additional-item .right {
  flex: unset;
  min-width: 220px;
}

.detailed-view--additional-item .left .info {
  flex: 1;
}
