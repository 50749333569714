@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@media (min-width: 1200px) and (max-width: 1350px) {

  .navbar-collapse .nav-item {
    margin-right: 10px;
  }
}

@include media-breakpoint-up(xl) {
  
  .navbar .left form {
    max-width: 240px;
  }
}

.navbar .navbar-nav .nav-link {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 3px;
    right: 3px;
    top: 100%;
    height: 2px;
    background-color: var(--primary);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &.active {
    color: var(--primary) !important;

    &::before {
      opacity: 1;
    }
  }
}
