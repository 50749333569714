.react-select__control {
  display: block;
  cursor: pointer !important;
  width: 100%;
  line-height: 2.08;
  color: #acacac;
  height: 3.333rem;
  border-color: #e1e1e1;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1 !important;
  border-radius: 5px;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .react-select__control {
    -webkit-transition: none;
    transition: none;
  }
}

.react-select__control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.react-select__control--is-focused {
  color: #495057;
  background-color: #fff;
  border-color: #d6ae3a !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(187, 142, 10, 0.25) !important;
}

.react-select__control::-webkit-input-placeholder {
  color: #acacac;
  opacity: 1;
}

.react-select__control::-moz-placeholder {
  color: #acacac;
  opacity: 1;
}

.react-select__control:-ms-input-placeholder {
  color: #acacac;
  opacity: 1;
}

.react-select__control::-ms-input-placeholder {
  color: #acacac;
  opacity: 1;
}

.react-select__control::placeholder {
  color: #acacac;
  opacity: 1;
}

.react-select__control:disabled,
.react-select__control[readonly] {
  background-color: #f9f9f9;
  opacity: 1;
}

.react-select__placeholder {
  color: #acacac !important;
}
