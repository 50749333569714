@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    h2 {
      font-weight: 600;
    }
  }
}
.acss-debit-card.active {
  background: #9E9E9E !important;
  .map-img {
    opacity: .6;
  }
}
.bank-no-found {
  width: 100%;
  margin: 0 auto;
  padding: 80px 30px;
  border-radius: 10px;
  span {
    max-width: 700px;
    margin: auto;
    font-weight: 400;
    font-size: 20px;
  }
}
.no-card-found {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 30px;
  p {
    font-size: 20px;
  }
  .addMethoad {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
    transition: .4s all;
  }
  .go-back {
    font-weight: 500;
    font-size: 16px;
  }
}
.acss-debit-card {
  height: 200px !important;
  cursor: pointer;
  &:hover {
    background: #9E9E9E !important;
  }
  .map-img {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%);
    height: 140px;
    max-width: 140px !important;
  }
  .rfid-img {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    opacity: .2;
  }
  .card-number {
    height: 100% !important;
    justify-content: end;
  }
}
.card-icon {
  width: 30px;
  border-radius: 6px;
}
.masked-card-number {
  font-size: 1.2rem;
  letter-spacing: 0.2em;
  display: block;
  color: #333;
  font-family: 'Courier New', Courier, monospace;
  margin-top: 5px;
  margin-left: 10px;
}
.yellow-button {
  background-color: #edab40;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  transition: 0.3s ease;
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

/* Grid for cards */
.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 cards per row */
  gap: 20px;
}
.delete-card {
  background-color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0 2px 20px rgba(0,0,0,0.2), 0 1px 3px rgba(0,0,0,0.1);
  border-radius: 10px;
  position: absolute;
  right: 20px;
  bottom: -31px;
  transition: .4s all;
  z-index: 10;

  &:hover {
    transform: scale(1.2);
    border: 1px solid #ff2c7d;
  }
  img {
    width: 15px;
  }
}

/* Card styles */
.card-inner {
  background-color: linear-gradient(45deg, #0045c7, #ff2c7d);
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 15px;
  overflow: hidden;
  z-index: 1;
  transition: .4s all;
  cursor: pointer;
  h5 {
    color: #333;
  }
  &:hover {
    .delete-card {
      bottom: 10px;
    }
  }
  &.expired {
    opacity: .6;
    pointer-events: none;
    border: 1px solid rgb(121, 2, 2);
    .checkbox {
      label {
        span {
          color:rgb(121, 2, 2) !important;
        }
      }
    }
    .exp-date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        font-size: 12px;
        font-weight: 600;
        margin: 10px 0 0;
        font-weight: 600;
        color: rgb(121, 2, 2);
      }
      .date-wrap {
        padding: 3px 0px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        color: rgb(121, 2, 2);
        font-family: "Space Mono", monospace;
      }
    }
    .card-number {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        font-size: 12px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 0;
        color: rgb(121, 2, 2);
      }
      .input-wrap {
        position: relative;
        width: 100%;
        background-color: transparent;
        padding: 3px 10px 3px 0px;
        border-radius: 7px;
        width: 100%;
        display: flex;
        align-items: center;
        border:0;
        span {
          color: rgb(121, 2, 2);
        }
      }
    }
  }

  &.active {
    // background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
    background:url('/assets/img/payment/payment-card-bg.svg');
    // background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
    background-position: center;
    background-size: cover;
    width: 100%;
    h5 {
      color: #fff;
    }
    .exp-date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        font-size: 12px;
        font-weight: 600;
        margin: 10px 0 0;
        font-weight: 600;
        color: #fff;
      }
      .date-wrap {
        padding: 3px 0px;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        color: #fff;
        font-family: "Space Mono", monospace;
      }
    }
    .card-number {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        font-size: 12px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 0;
        color: #fff;
      }
      .input-wrap {
        position: relative;
        width: 100%;
        background-color: transparent;
        padding: 3px 10px 3px 0px;
        border-radius: 7px;
        width: 100%;
        display: flex;
        align-items: center;
        border:0;
        span {
          color: #fff;
        }
      }
    }
  }
  .map-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
    max-width: 100%;
  }
  .chip-img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
  }
  
  .exp-date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
      font-size: 12px;
      font-weight: 600;
      margin: 10px 0 0;
      font-weight: 400;
      color: #000;

    }
    .date-wrap {
      padding: 3px 0px;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 12px;
    }
  }
  
  .card-number {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 55%;
    justify-content: end;
    label {
      font-size: 12px;
      font-weight: 600;
      margin-top: 15px;
      margin-bottom: 0;
      color: #000;
    }
    .input-wrap {
      position: relative;
      width: 100%;
      background-color: transparent;
      border: 0;
      padding: 3px 10px 3px 0px;
      border-radius: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 100%;
        word-spacing: 6px;
        font-size: 20px;
        letter-spacing: 2px;
        text-align: left;
        font-family: "Space Mono", monospace;
        font-weight: 500;
        font-style: normal;
      }
    }
  }
}

.card-inner:hover {
  background-color: #edab40;
  label {
    color: #fff;
  }
  span {
    color: #fff !important;
  }
  h5 {
    color: #fff;
  }
}

/* Payment logos */
.card-inner img {
  max-width: 100px;
}

.card-inner h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}
.card-inner .cardheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    font-size: 12px;
    font-weight: 600;
  }
}
.form-group.checkbox {
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  .custom-checkbox {
    line-height: 8px;
  }
  label {
    cursor: pointer;
    font-size: 13px;
  }
}

.left-check {
  .emp-circle {
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
    display: inline-block;
  }
}

.modal-header {
  background-color: #f4f4f4;
  border: none;
  -webkit-box-pack: center;
  h3 {
    font-size: 20px;
    text-align: center;
  }
}
.modal-payment-method {
  input {
    font-size: 12px;
  }
  select {
    font-size: 12px;
  }
}
.custom-checkbox-input {
  display: flex;
  align-items: center;
  border: 1px solid #E4E7EC;
  padding: 10px 10px;
  border-radius: 5px;
  position: relative;
}
.email-icon-wrap {
  input {
    padding-left: 30px;
  }
  img {
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
}

.circle-check {
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  img {
    width: 10px;
    height: 10px;
  }
}

.circle-check.checked {
  background-color: transparent;
  border-color: #7F56D9;
  color: white;
}

.input-field {
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 14px;
  color: #333;
}

.input-field::placeholder {
  color: #aaa;
}

/* Optional hover effect */
.circle-check:hover {
  border-color: #888;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr; /* Stack cards on smaller screens */
  }
}