.cost {
  margin-left: 8;
}
.presetItem {
  font-weight: bold;
}
.menuItem {
  font-size: 0.8rem;
}
.specialInstruction.head {
  font-size: 0.8rem;
  white-space: nowrap;
  margin-right: 1rem;
}

.specialInstruction.body {
  font-size: 0.8rem;
  white-space: pre-wrap;
}

.item-list {
  cursor: pointer;
}

.item-list .badge {
  margin-left: 0;
  margin-right: 0.5rem;
}

.order-details .card-body {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.order-details .item-list {
  border-width: 0 0 3px 0;
  /* border-color: #E69221; */
}

.order-details .item-list li:not(:last-child) {
  border-bottom: none;
}

.order-details .item-list li {
  padding: 6px 0;
  border-bottom: none;
}

.order-details .total li:last-child {
  padding-top: 0;
  font-weight: normal;
  border-top: none;
}

.order-details .item-list li.li--instruction {
  align-items: baseline;
  justify-content: unset;
}
