.mt-20 {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .home--carousel.wid-25 {
    width: 25%;
  }

  .home--carousel.wid-50 {
    width: 50%;
  }

  .home--carousel.wid-75 {
    width: 75%;
  }

  .slick-grid .slick-list {
    margin: 0 !important;
    /* margin: 0 -15px !important; */
    /* I'm commenting this out because if we have this CSS, then we will can see 5th slick card ... */
  }
}

.slick-prev:after {
  margin: auto;
}

.slick-next:after {
  margin: auto;
}

@media (min-width: 1200px) {
  .home--picture img {
    position: absolute;
    right: 12px;
    max-width: 575px;
  }
}

.mt-25 {
  margin-top: 25px;
}

.labeled-group.floating .label-focused {
  top: 6px !important;
  font-size: 11px !important;
  opacity: 1 !important;
}

.labeled-group.floating .form-control:not(:focus):valid ~ label
/*
-- need to remove this CSS inside main.css because we're using custom Formik error messages instead of HTML default validation
So I'm adding default attributes now.
*/
 {
  top: 0.95rem;
  font-size: 14px;
  opacity: 1;
}

.autocomplete-dropdown-container {
  margin-top: 6px;
}

/* Fluid image should have width 100% if the original image size is smaller ... */
.img-fluid {
  width: 100%;
}

.datepicker-container {
  display: block;
}

.home--form-top {
  z-index: 2;
}

.home--form {
  overflow: visible;
}

.field-group :last-child input.datepicker-home--left {
  border-width: 1px;
  border-radius: 5px 0 0 5px;
}

.field-group :last-child input.datepicker-home--right {
  border-width: 1px;
  border-left: 0;
  border-radius: 0 5px 5px 0;
}

/* .order-details {
  max-height: calc(100vh - 200px);
} */

.order-details .card-body {
  overflow-y: auto;
}

.order-details .card-body::-webkit-scrollbar {
  width: 3px;
}

.order-details .card-body::-webkit-scrollbar-thumb {
  background-color: rgba(242, 20, 0, 0.7);
}

ul.restaurants li {
  display: flex;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 100px;
}
.restaurants img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: auto;
  border-radius: 100px;
}

.modal-signup-confirm {
  z-index: 1061;
}

.meal-card.slide .overlay {
  background-color: rgba(187, 142, 10, 0.2);
  padding: 4rem 2rem;
  justify-content: flex-start;
}

.navbar {
  z-index: 3; /* Need to be on top of the sticky cart */
}

.btn-clipboard {
  position: relative;
  width: 100%;
  padding: 0 10px;
}

.btn-clipboard:hover {
  background-color: white;
}

.btn-clipboard:hover p {
  display: block;
}

.btn-clipboard:hover span {
  display: none;
}

.btn-clipboard p {
  display: none;
  border: none;
  font-weight: normal;
  font-size: 14px;
  color: #E69221;
  background: transparent;
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 30px 0 0;
}

.btn-clipboard img {
  position: absolute;
  right: 10px;
  width: 20px;
  height: auto;
}

.form-group .form-control {
  height: 3.333rem;
}
