.header {
  padding: 30px 40px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  .arrow {
    position: absolute;
    right: 25px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    &:after,
    &:before {
      content: "";
      transition: 0.4s;
      width: 20px;
      height: 2px;
      border-radius: 1px;
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 1px);
      background-color: #000;
    }
    &:after{
      transform: rotate(0deg);
    }
    &:before{
      transform: rotate(90deg);
    }
  }
}

// accordion
.header.active {
  border-radius: 10px 10px 0 0;
  .arrow {
    transform: none;
    &:after{
      transform: rotate(135deg);
    }
    &:before{
      transform: rotate(225deg);
    }
  }
}

.contentWrapp {
  transition: max-height 0.4s;
  max-height: 0;

  > div {
    transition: 0.4s;
    opacity: 0;
    padding: 30px 40px;
  }
  iframe{
    width: 100%;
    height: 500px;
  }
  img{
    max-width: 100%;
  }
}

.contentWrapp.open {
  visibility: initial;
  height: 100%;
  z-index: 1;
  position: relative;

  > div {
    opacity: 1;
    height: 100%;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0px 0px 10px 10px;
  }
}

@media screen and (max-width: 1023px) {
  .header {
    padding: 20px 40px 20px 20px;
    .arrow {
      right: 20px;
    }
  }
  .contentWrapp {
    > div {
      padding: 20px;
    }
  }
}
